/**
 * Created by u067265 on 2/23/17.
 */
(function () {

    'use strict';

    window.addEventListener('load', function () {
        $('ul.edlc-collapsible-nav').eq(0).edlcCollapsibleNav();
    });

}());
